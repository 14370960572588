/**  @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import { Helmet } from 'react-helmet';
import { jsx, useThemeUI } from 'theme-ui';

export default function BaseLayout({ children, ...rest }) {
  const { theme = {} } = useThemeUI();
  // @ts-ignore
  const { fontUrls = [] } = theme;
  return (
    <Box
      sx={{ minHeight: '100vh', flexDirection: 'column', display: 'flex' }}
      {...rest}
    >
      <Helmet>
        {fontUrls.map((url) => (
          <link key={url} rel="stylesheet" href={url}></link>
        ))}
      </Helmet>
      {children}
    </Box>
  );
}
