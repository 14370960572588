/** @jsx jsx */
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import { Flex, Text } from '@bottlebooks/gatsby-theme-base';
import {
  CallToAction,
  RichText,
  Section,
} from '@bottlebooks/gatsby-theme-cms/src/components';

export default function TextSection({
  title,
  _key,
  style,
  callToAction,
  _rawText,
}) {
  return (
    <Section key={_key} variant={style.variant} sx={style}>
      <Flex
        direction="column"
        align={
          style.alignment === 'center'
            ? 'center'
            : style.alignment === 'right'
            ? 'flex-end'
            : 'flex-start'
        }
      >
        <Section.Title
          // Force black when against accent
          sx={{
            textAlign: style.alignment,
            ...(style.variant === 'accent' && { color: 'white' }),
          }}
        >
          {title}
        </Section.Title>
        <Section.Body>
          <Text
            sx={{
              textAlign: style.alignment,
              ...(style.variant === 'accent' && { color: 'white' }),
            }}
          >
            <RichText>{_rawText}</RichText>
          </Text>
          <CallToAction
            {...callToAction}
            sx={{
              marginTop: 3,
              ...(style.variant === 'accent' && { color: 'white' }),
            }}
          />
        </Section.Body>
      </Flex>
    </Section>
  );
}

export const fragment = graphql`
  fragment SanityTextSectionFragment on SanityTextSection {
    title
    _rawText
    callToAction {
      ...CallToAction
    }
    style {
      ...SectionStyle
    }
  }
`;
